<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="115"
    height="114"
    viewBox="0 0 115 114"
  >
    <title>Incident</title>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_22"
          data-name="Rectangle 22"
          width="42"
          height="36"
          transform="translate(-0.5 0.223)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Group_122"
      data-name="Group 122"
      transform="translate(0.017 -0.341)"
    >
      <g
        id="Group_113"
        data-name="Group 113"
        transform="translate(0)"
      >
        <g
          id="Group_111"
          data-name="Group 111"
          transform="translate(0 0)"
        >
          <g
            id="Group_109"
            data-name="Group 109"
            transform="translate(0 0)"
            opacity="0.362"
          >
            <ellipse
              id="Ellipse_9"
              data-name="Ellipse 9"
              cx="57.5"
              cy="57"
              rx="57.5"
              ry="57"
              transform="translate(-0.017 0.341)"
              fill="#e1001e"
            />
          </g>
          <g
            id="Group_110"
            data-name="Group 110"
            transform="translate(19.746 19.746)"
          >
            <circle
              id="Ellipse_9-2"
              data-name="Ellipse 9"
              cx="37.5"
              cy="37.5"
              r="37.5"
              transform="translate(0.237 -0.405)"
              fill="#e1001e"
            />
          </g>
        </g>
      </g>
    </g>
    <g
      id="Group_145"
      data-name="Group 145"
      transform="translate(-132.5 -241.223)"
    >
      <g
        id="Group_144"
        data-name="Group 144"
        transform="translate(170 276)"
      >
        <g
          id="Group_143"
          data-name="Group 143"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_134"
            data-name="Path 134"
            d="M187.66,277.867l-16.975,29.4a3.492,3.492,0,0,0,3.025,5.239h33.947a3.492,3.492,0,0,0,3.025-5.239l-16.973-29.4a3.491,3.491,0,0,0-6.048,0Zm0,0"
            transform="translate(-170.184 -276.103)"
            fill="#fec000"
          />
        </g>
      </g>
      <path
        id="Path_135"
        data-name="Path 135"
        d="M292.517,347.332h.443a1.97,1.97,0,0,1,1.974,1.974v9.435a1.97,1.97,0,0,1-1.974,1.974h-.443a1.97,1.97,0,0,1-1.974-1.974v-9.435a1.97,1.97,0,0,1,1.974-1.974Zm0,0"
        transform="translate(-102.238 -60.5)"
      />
      <path
        id="Path_136"
        data-name="Path 136"
        d="M294.934,458.023a2.2,2.2,0,1,1-2.2-2.2,2.2,2.2,0,0,1,2.2,2.2Zm0,0"
        transform="translate(-102.238 -152.52)"
      />
    </g>
  </svg>
</template>
