<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 115 115"
    style="enable-background:new 0 0 115 115;"
    xml:space="preserve"
  >
    <title>Hospital</title>
    <g
      id="Group_136"
      transform="translate(0.454 0.383)"
    >
      <g
        id="Group_121"
        transform="translate(0)"
      >
        <g
          id="Group_113"
          transform="translate(0 0)"
        >
          <g
            id="Group_111"
            transform="translate(0 0)"
          >
            <g
              id="Group_109"
              transform="translate(0 0)"
              class="st0"
              style="opacity:0.362;"
            >
              <circle
                id="Ellipse_9"
                class="st1"
                fill="#C7C7C7"
                cx="57"
                cy="57.1"
                r="57.5"
              />
            </g>
            <g
              id="Group_110"
              transform="translate(19.746 19.746)"
            >
              <g
                id="Ellipse_9-2"
                transform="translate(-0.2 -0.129)"
              >
                <circle
                  class="st2"
                  fill="#FFFFFF"
                  cx="37.5"
                  cy="37.5"
                  r="37.5"
                />
                <circle
                  class="st3"
                  fill="none"
                  stroke="#E1001E"
                  stroke-width="3"
                  cx="37.5"
                  cy="37.5"
                  r="36"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g class="st4">
        <path
          class="st5"
          fill="#E1001E"
          d="M51.7,71.6h-6.4V42.4h6.4v11.8h12.1V42.4h6.4v29.2h-6.4V58.9H51.7V71.6z"
        />
      </g>
    </g>
  </svg>
</template>
